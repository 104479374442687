import React, { Fragment, useEffect, useState } from 'react';
import { getTemplatePage, ReqTemplatePage, TemplateCreatedType } from '../../api/templateAPI';
import TemplateBreadcrumb from './component/TemplateBreadcrumb';
import { Col, Container, Row } from 'reactstrap';
import useApiError from '../../hooks/useApiError';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDefaultFilter from '../../hooks/useDefaultFilter';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../utils/urlUtils';
import { useQuery } from 'react-query';
import TbFilter from '../../components/common/ui/TbFilter';
import TbSelect from '../../components/common/ui/form/TbSelect';
import TbInputColor from '../../components/common/ui/form/TbInputColor';
import DataTable from 'react-data-table-component';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';
import { templateColumns } from './data/templateColumns';

const TemplateList = ({ createdType }: { createdType: TemplateCreatedType }) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  const [templateData, setTemplateData] = useState<any>([]);
  const [totalRows, setTotalRows] = useState(0);
  // query string 값으로 request 파라미터 초기화.
  const [reqParams, setReqParams] = useState<ReqTemplatePage>({
    ...defaultFilter,
    type: 'DESIGN',
    createdType: createdType,
    // Custom Filter
    hexColor: searchParams.get('hexColor') || '',
    idCategory: searchParams.get('idCategory') || '',
    idForm: searchParams.get('idForm') || '',
    active: searchParams.get('active') === 'true' ? true : searchParams.get('active') === 'false' ? false : null,
  });

  // createdType 변경에 따른 파라미터 업데이트.
  useEffect(() => {
    setReqParams({
      ...reqParams,
      createdType,
    });
  }, [createdType]);

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * 목록 조회.
   * */
  const { isLoading } = useQuery(['getTemplatePage', reqParams], () => getTemplatePage(reqParams), {
    onSuccess: (data) => {
      setTemplateData(data?._embedded?.templates || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * TODO 카테고리 목록 요청.
   * */

  /**
   * TODO 양식 목록 요청.
   * */

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 한 페이지 당 보여줄 행 수 변경 핸들러
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 행 클릭 핸들러
   * */
  const handleRowClicked = (row: any) => {
    if (createdType === 'BRAND') {
      navigate(`/admin/template-brand/update/${row.idTemplate}`);
    } else if (createdType === 'MARKET') {
      navigate(`/admin/template-market/update/${row.idTemplate}`);
    }
  };

  /**
   * 검색 핸들러
   * */
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      ...filters,
    });
  };

  return (
    <Fragment>
      {createdType === 'BRAND' && (
        <TemplateBreadcrumb title={'브랜드 템플릿'} createTo={'/admin/template-brand/create'} />
      )}
      {createdType === 'MARKET' && (
        <TemplateBreadcrumb title={'마켓 템플릿'} createTo={'/admin/template-market/create'} />
      )}
      <Container fluid={true}>
        {/* :::::::::: Filter : Start ::::::::::  */}
        <TbFilter onSubmit={onSubmitSearch} defaultValues={reqParams}>
          {({ register, errors, setValue }) => {
            setValue('hexColor', reqParams.hexColor);
            setValue('idCategory', reqParams.idCategory);
            setValue('idForm', reqParams.idForm);
            setValue('active', reqParams.active);
            return (
              <Row>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">카테고리</label>
                    </Col>
                    <Col sm={10}>
                      <TbSelect
                        placeholder={'전체'}
                        name="idCategory"
                        options={[]}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">양식</label>
                    </Col>
                    <Col sm={10}>
                      <TbSelect placeholder={'전체'} name="idForm" options={[]} register={register} errors={errors} />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">활성상태</label>
                    </Col>
                    <Col sm={10}>
                      <TbSelect
                        placeholder={'전체'}
                        name="active"
                        options={[
                          { label: '활성', value: 'true' },
                          { label: '비활성', value: 'false' },
                        ]}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">HEX 색상</label>
                    </Col>
                    <Col sm={10}>
                      {reqParams.hexColor}
                      <div className={'d-flex'}>
                        <TbInputColor id={'hexColor'} name={'hexColor'} register={register} errors={errors} />
                        <div
                          onClick={() => {
                            setValue('hexColor', '');
                          }}
                          className={'btn btn-link btn-sm px-2'}
                        >
                          Reset
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          }}
        </TbFilter>
        {/* :::::::::: Filter : End ::::::::::  */}

        {/*  :::::::::: List ::::::::::  */}
        <DataTable
          noHeader
          columns={templateColumns()}
          data={templateData}
          progressPending={isLoading}
          highlightOnHover
          pointerOnHover
          pagination
          paginationServer
          paginationDefaultPage={(reqParams.page || 0) + 1}
          paginationTotalRows={totalRows}
          paginationPerPage={reqParams.size}
          paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={handleRowClicked}
          dense={true}
          customStyles={{
            rows: rowsStyle,
            headCells: headCellsStyle,
            cells: cellsStyle,
          }}
        />
      </Container>
    </Fragment>
  );
};

export default React.memo(TemplateList);
