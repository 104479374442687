import React, { Fragment, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import TbModal from '../../components/common/ui/TbModal';
import { useNavigate, useParams } from 'react-router-dom';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import { getFontFamily, getFontFamilyEnum } from '../../api/fontAPI';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TbLoading from '../../components/common/ui/TbLoading';
import Breadcrumb from '../../components/common/breadcrumb';
import TbInput from '../../components/common/ui/form/TbInput';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbDateText from '../../components/common/ui/TbDateText';
import TbSelect from '../../components/common/ui/form/TbSelect';
import TbInputUploadImage from '../../components/common/ui/form/file/TbResourceFileUpload';
import { createFontFace, deleteFontFace, getFontFace, ReqFontFace, updateFontFace } from '../../api/fontFaceAPI';

const FontFaceUpdate = () => {
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idFontFamily, idFontFace } = useParams<{ idFontFamily: string; idFontFace: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteMutate = useMutation(deleteFontFace);
  const createMutate = useMutation(createFontFace);
  const updateMutate = useMutation(updateFontFace);

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqFontFace>({
    defaultValues: {
      ...(!idFontFace
        ? // 생성일 경우 초기값.
          {
            active: false,
          }
        : {}),
    },
  });

  const watchWeight = watch('weight');
  const watchImage = watch('image');
  const watchOtf = watch('otf');
  const watchTtf = watch('ttf');
  const watchWoff = watch('woff');
  const watchWoff2 = watch('woff2');

  /**
   * Enum 리스트 조회.
   * */
  const { data: enumList } = useQuery(['getFontFamilyEnum'], () => getFontFamilyEnum(), {
    select: (data) => {
      return {
        weightList: Object.entries(data.weightList).map(([key, value]) => ({
          value: key,
          label: `${value} - ${key}`,
        })),
      };
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 초기 폰트 패밀리 데이터 로드
   * */
  const { data: fontFamilyData } = useQuery(
    ['getFontFamily', idFontFamily],
    () => getFontFamily(Number(idFontFamily)),
    {
      enabled: !!idFontFamily,
      select: (data) => {
        return {
          ...data,
        };
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData } = useQuery(
    ['getFontFace', idFontFace, idFontFamily],
    () => getFontFace(Number(idFontFace)),
    {
      enabled: !!idFontFamily && !!idFontFace && !!enumList,
      select: (data) => {
        return {
          ...data,
        };
      },
      onSuccess: (data) => {
        setValue('weight', data.weight);
        setValue('weightName', data.weightName);
        setValue('image', data.image);
        setValue('otf', data.otf);
        setValue('ttf', data.ttf);
        setValue('woff', data.woff);
        setValue('woff2', data.woff2);

        setValue('active', data.active);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(false);
    if (idFontFace && Number(idFontFace) > 0) {
      if (defaultData?.idFontFamily === Number(idFontFamily)) {
        //Update
        try {
          await updateMutate.mutateAsync({
            idFontFace: Number(idFontFace),
            body: { ...data, idFontFamily: idFontFamily } as ReqFontFace,
          });
          toast.success('요소 수정이 완료되었습니다.');
          navigate(-1);
        } catch (error) {
          handleApiError(error);
        }
      }
    } else {
      // Create
      try {
        await createMutate.mutateAsync({
          ...data,
          idFontFamily: idFontFamily,
        });
        toast.success('요소가 생성되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idFontFace) {
      try {
        await deleteMutate.mutateAsync(Number(idFontFace));
        navigate(-1);
        toggleDeleteModal();
        toast.success('요소가 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  /**
   * 업로드 파일의 공통 이름(확장자만 다르고 이름은 동일하게 업로드)
   * 지정한 폰트 파일 네임과 두께 enum 값의 조합으로 파일명을 생성.
   * */
  const uploadFileName = useMemo(() => {
    return `${fontFamilyData?.fileName}_${watchWeight?.toLowerCase()}`;
  }, [fontFamilyData?.fileName, watchWeight]);

  /**
   * 업로드 파일의 공통 URL
   * 호스트, 기본경로(service/fonts), 폰트 파일 이름 의 조합으로 고유 경로가 생성되기 때문에 아래와 같이 예측 가능.
   * */
  const uploadFileUrl = useMemo(() => {
    return `${process.env.REACT_APP_SERVICE_RESOURCES_URL}/${fontFamilyData?.filePath}`;
  }, [fontFamilyData?.filePath]);

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <Breadcrumb parent="폰트 패밀리" title={`폰트 페이스 ${idFontFace ? '수정' : '생성'}`}>
            <div className="mb-0">
              {idFontFace ? (
                <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                  삭제
                </Button>
              ) : (
                ''
              )}

              <Button color="secondary" className="me-3" type="submit">
                {isLoading ? <TbLoading /> : idFontFace ? '수정' : '생성'}
              </Button>
              <Button onClick={() => navigate(-1)} color="primary">
                목록
              </Button>
            </div>
          </Breadcrumb>

          <Row>
            <Col sm={7}>
              <Card>
                <CardHeader>
                  <h5>폰트 정보</h5>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="폰트 페이스 표기명"
                    name={'weightName'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbSelect
                    label="두께"
                    message={<div className={'text-danger'}>* 최초 등록 후 수정 불가합니다.</div>}
                    name="weight"
                    options={enumList?.weightList ? enumList?.weightList : []}
                    readonly={!!idFontFace}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInputUploadImage
                    type={'FILE'}
                    path={`service/fonts/${fontFamilyData?.fileName}`} // s3의 element 경로에 업로드
                    fileName={`${uploadFileName}.png`}
                    control={control}
                    label={'썸네일'}
                    message={
                      <div>
                        <div className={'text-danger'}>* 두께를 먼저 선택 후 업로드하세요.</div>* 1MB 이하 PNG 형식만
                        업로드 가능합니다.
                      </div>
                    }
                    accept={'.png'}
                    maxSize={1}
                    initialData={watchImage ? `${uploadFileUrl}/${watchImage}` : ''}
                    onComplete={() => {
                      setValue('image', `${uploadFileName}.png`);
                    }}
                    onDeleteComplete={() => {
                      setValue('image', '');
                    }}
                    errors={errors}
                    disabled={!watchWeight}
                  />

                  {watchImage && (
                    <>
                      <img height={30} alt={watchImage} src={`${uploadFileUrl}/${watchImage}`} />
                    </>
                  )}

                  <br />
                  <hr />
                  <br />
                  <TbInputUploadImage
                    type={'FILE'}
                    path={`service/fonts/${fontFamilyData?.fileName}`} // s3의 element 경로에 업로드
                    fileName={`${uploadFileName}.otf`}
                    control={control}
                    label={'OTF 업로드'}
                    accept={'.otf'}
                    maxSize={50}
                    initialData={watchOtf ? `${uploadFileUrl}/${watchOtf}` : ''}
                    onComplete={() => {
                      setValue('otf', `${uploadFileName}.otf`);
                    }}
                    onDeleteComplete={() => {
                      setValue('otf', '');
                    }}
                    errors={errors}
                  />
                  <TbInputUploadImage
                    type={'FILE'}
                    path={`service/fonts/${fontFamilyData?.fileName}`} // s3의 element 경로에 업로드
                    fileName={`${uploadFileName}.ttf`}
                    control={control}
                    label={'TTF 업로드'}
                    accept={'.ttf'}
                    maxSize={50}
                    initialData={watchTtf ? `${uploadFileUrl}/${watchTtf}` : ''}
                    onComplete={() => {
                      setValue('ttf', `${uploadFileName}.ttf`);
                    }}
                    onDeleteComplete={() => {
                      setValue('ttf', '');
                    }}
                    errors={errors}
                  />
                  <TbInputUploadImage
                    type={'FILE'}
                    path={`service/fonts/${fontFamilyData?.fileName}`} // s3의 element 경로에 업로드
                    fileName={`${uploadFileName}.woff`}
                    control={control}
                    label={'WOFF 업로드'}
                    accept={'.woff'}
                    maxSize={50}
                    initialData={watchWoff ? `${uploadFileUrl}/${watchWoff}` : ''}
                    onComplete={() => {
                      setValue('woff', `${uploadFileName}.woff`);
                    }}
                    onDeleteComplete={() => {
                      setValue('woff', '');
                    }}
                    errors={errors}
                  />

                  <TbInputUploadImage
                    type={'FILE'}
                    path={`service/fonts/${fontFamilyData?.fileName}`} // s3의 element 경로에 업로드
                    fileName={`${uploadFileName}.woff2`}
                    control={control}
                    label={'WOFF2 업로드'}
                    accept={'.woff2'}
                    maxSize={50}
                    initialData={watchWoff2 ? `${uploadFileUrl}/${watchWoff2}` : ''}
                    onComplete={() => {
                      setValue('woff2', `${uploadFileName}.woff2`);
                    }}
                    onDeleteComplete={() => {
                      setValue('woff2', '');
                    }}
                    errors={errors}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm={5}>
              <Card>
                <CardHeader>
                  <h5>설정</h5>
                </CardHeader>
                <CardBody>
                  <TbRadioBoolean
                    control={control}
                    message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                    label={'게시 상태'}
                    name="active"
                    register={register}
                  />
                  {idFontFace && (
                    <>
                      <TbDateText label={'생성일'} date={defaultData?.createdDate} />
                      <TbDateText label={'수정일'} date={defaultData?.updatedDate} />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default React.memo(FontFaceUpdate);
