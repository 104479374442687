import { Link } from 'react-router-dom';
import Breadcrumb from '../../../components/common/breadcrumb';
import React from 'react';

const TemplateBreadcrumb = ({ title, createTo }: { title: string; createTo: string }) => {
  return (
    <Breadcrumb parent="템플릿 관리" title={title}>
      <Link className="btn btn-primary" to={createTo}>
        새로 등록
      </Link>
    </Breadcrumb>
  );
};
export default React.memo(TemplateBreadcrumb);
