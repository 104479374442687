import {
  AlignRight,
  Bell,
  Bold,
  Box,
  CheckSquare,
  CreditCard,
  ExternalLink,
  Headphones,
  Image,
  RefreshCcw,
  Search,
  Settings,
  ShoppingCart,
  StopCircle,
  Truck,
  Type,
  Users,
} from 'react-feather';

export const MENUITEMS = [
  // {
  //   title: 'Dashboard',
  //   icon: Home,
  //   type: 'sub',
  //   badgeType: 'primary',
  //   active: false,
  //   children: [
  //     { path: `/dashboard`, title: 'Default', type: 'link' },
  //     { path: `/workflow`, title: 'WorkFlow', type: 'link' },
  //   ],
  // },
  {
    title: '고객 관리',
    type: 'label',
  },
  {
    title: '엔터프라이즈 고객',
    icon: Users,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/workflow/logo`,
    children: [
      { path: `/admin/workflow/logo`, title: '로고 고객', type: 'link' },
      { path: `/admin/workflow/list`, title: '일반 고객', type: 'link' },
    ],
  },
  {
    title: '회원 구독 관리',
    icon: Bell,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/subscription/list`,
  },
  {
    title: '결제 내역',
    icon: CreditCard,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/payment/list`,
  },
  {
    title: '크레딧 관리',
    icon: StopCircle,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/credit/list`,
    // children: [{ path: `/workflow`, title: 'WorkFlow', type: 'link' }],
  },
  {
    title: '요금제 & 상품',
    icon: CheckSquare,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/plan/list`,
    children: [
      { path: `/admin/plan/list`, title: '구독 요금제', type: 'link' },
      { path: `/admin/credit-plan/list`, title: '크레딧 상품', type: 'link' },
      { path: `/admin/product/list`, title: '로고 상품 ', type: 'link' },
    ],
  },
  {
    title: '디자이너 작업 관리',
    icon: AlignRight,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/task/list`,
    // children: [{ path: `/workflow`, title: 'WorkFlow', type: 'link' }],
    children: [
      { path: `/admin/task/list`, title: '신청 작업 관리', type: 'link' },
      { path: `/admin/task/product/category/list`, title: '작업 카테고리', type: 'link' },
      { path: `/admin/task/product/list`, title: '작업 상품 ', type: 'link' },
    ],
  },
  {
    title: '문의 내역 관리',
    icon: Headphones,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/contact/list`,
    children: [
      { path: `/admin/contact/list`, title: '일반 문의 관리', type: 'link' },
      { path: `/admin/logoquest/list`, title: '로고 의뢰 관리', type: 'link' },
    ],
  },
  {
    title: '브랜드 관리',
    type: 'label',
  },
  {
    title: '브랜드 관리',
    icon: Bold,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/coming-soon`,
    children: [
      { path: `/admin/coming-soon`, title: '고객 브랜드', type: 'link' },
      { path: `/admin/coming-soon`, title: '마켓 브랜드', type: 'link' },
    ],
  },
  {
    title: '브랜드 키워드 관리',
    icon: Search,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/keyword/list`,
  },
  {
    title: '산업군 관리',
    icon: Truck,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/industry/category/list`,
    children: [
      { path: `/admin/industry/category/list`, title: '카테고리', type: 'link' },
      { path: `/admin/industry/list`, title: '산업군', type: 'link' },
    ],
  },
  {
    title: '디자인 리소스',
    type: 'label',
  },
  {
    title: '검색 키워드 관리',
    icon: Search,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/search-keyword/list`,
  },
  {
    title: '에디터 요소 관리',
    icon: Box,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/editor-element/list`,
    children: [
      { path: `/admin/editor-element/category/list`, title: '카테고리 관리', type: 'link' },
      { path: `/admin/editor-element/collection/list`, title: '컬렉션 관리', type: 'link' },
      { path: `/admin/editor-element/list`, title: '요소 관리', type: 'link' },
      { path: `/admin/editor-element/batch`, title: '요소 일괄 업로드', type: 'link' },
    ],
  },
  {
    title: '폰트 패밀리 관리',
    icon: Type,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/font/list`,
  },
  {
    title: '템플릿 관리',
    icon: ShoppingCart,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/template-market/list`,
    children: [
      { path: `/admin/template-brand/list`, title: '고객 브랜드 템플릿', type: 'link' },
      { path: `/admin/template-market/list`, title: '마켓 템플릿', type: 'link' },
      { path: `/admin/template-bundle/list`, title: '번들 관리', type: 'link' },
      { path: `/admin/template-form/list`, title: '양식 관리', type: 'link' },
    ],
  },
  {
    title: '도구 모음',
    type: 'label',
  },
  {
    title: 'Ideogram',
    icon: Image,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/tools/third-party/ideogram`,
  },
  {
    title: 'RemoveBg 배경제거',
    icon: Image,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/tools/third-party/removebg`,
  },
  {
    title: '결제 URL 생성',
    icon: ExternalLink,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/tools/pay-url/universal`,
    children: [
      { path: `/admin/tools/pay-url/logo`, title: '로고 결제 URL', type: 'link' },
      { path: `/admin/tools/pay-url/universal`, title: '다용도 결제 URL', type: 'link' },
    ],
  },
  {
    title: '로고 컨버터',
    icon: RefreshCcw,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/tools/logo-converter`,
  },
  {
    title: '시스템 설정',
    type: 'label',
  },
  {
    title: '언어 설정',
    icon: Settings,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: `/admin/setting`,
  },
];
