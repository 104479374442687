import React from 'react';
import TBFlag from '../../../components/common/ui/TBFlag';

export const FontFamilyColumns = () => [
  {
    name: 'id',
    selector: (row: any) => row.idFontFamily,
    center: true,
    width: '60px',
  },
  {
    name: '폰트패밀리',
    selector: (row: any) => row.name,
    sortable: true,
    center: true,
    wrap: true,
  },
  // {
  //   name: '표기명',
  //   cell: (row: any) => {
  //     return (
  //       <div>
  //         {row.i18ns?.map((i18n: ResFontFamilyI18n) => {
  //           return (
  //             <div key={i18n.displayName}>
  //               <TBFlag locale={i18n.locale} />
  //               <span className={'m-l-5'}>{i18n.displayName}</span>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     );
  //   },
  //   sortable: true,
  //   center: true,
  //   wrap: true,
  // },
  {
    name: '지원언어',
    cell: (row: any) => {
      // return row.localeList?.join(', ');
      return row.localeList.map((locale: any) => {
        return (
          <span key={locale} className={'m-r-5'}>
            <TBFlag locale={locale} />
          </span>
        );
      });
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '100px',
  },
  {
    name: '제목',
    cell: (row: any) => {
      return row.headerFont ? (
        <span className="badge badge-success">O</span>
      ) : (
        <span className="badge badge-danger">X</span>
      );
    },
    // sortable: true,
    center: true,
    wrap: true,
    width: '50px',
  },
  {
    name: '본문',
    cell: (row: any) => {
      return row.bodyFont ? (
        <span className="badge badge-success">O</span>
      ) : (
        <span className="badge badge-danger">X</span>
      );
    },
    // sortable: true,
    center: true,
    wrap: true,
    width: '50px',
  },
  {
    name: '손글씨',
    cell: (row: any) => {
      return row.handwriting ? (
        <span className="badge badge-success">O</span>
      ) : (
        <span className="badge badge-danger">X</span>
      );
    },
    // sortable: true,
    center: true,
    wrap: true,
    width: '50px',
  },
  {
    name: '필기체',
    cell: (row: any) => {
      return row.cursive ? (
        <span className="badge badge-success">O</span>
      ) : (
        <span className="badge badge-danger">X</span>
      );
    },
    // sortable: true,
    center: true,
    wrap: true,
    width: '50px',
  },
  {
    name: 'Serif',
    cell: (row: any) => {
      return row.serif ? <span className="badge badge-success">O</span> : <span className="badge badge-danger">X</span>;
    },
    // sortable: true,
    center: true,
    wrap: true,
    width: '50px',
  },
  {
    name: '고딕',
    cell: (row: any) => {
      return row.gothic ? (
        <span className="badge badge-success">O</span>
      ) : (
        <span className="badge badge-danger">X</span>
      );
    },
    // sortable: true,
    center: true,
    wrap: true,
    width: '50px',
  },
  {
    name: '라운드',
    cell: (row: any) => {
      return row.round ? <span className="badge badge-success">O</span> : <span className="badge badge-danger">X</span>;
    },
    // sortable: true,
    center: true,
    wrap: true,
    width: '50px',
  },
  {
    name: '디자인',
    cell: (row: any) => {
      return row.design ? (
        <span className="badge badge-success">O</span>
      ) : (
        <span className="badge badge-danger">X</span>
      );
    },
    // sortable: true,
    center: true,
    wrap: true,
    width: '50px',
  },
  {
    name: '활성 여부',
    cell: (row: any) => {
      return row.active ? (
        <span className="badge badge-success">활성</span>
      ) : (
        <span className="badge badge-danger">비활성</span>
      );
    },
    // sortable: true,
    center: true,
    wrap: true,
    width: '70px',
  },
];
