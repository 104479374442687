import React, { ReactElement } from 'react';
import { TBFormGroup, TBLabel } from '../../styledComponents';
import styled from 'styled-components';
import { InputGroup, InputGroupText } from 'reactstrap';

interface InputProps {
  label?: string;
  message?: string | ReactElement;
  id?: string;
  name: string;
  type: string;
  min?: number;
  max?: number;
  step?: number;
  inputGroupTextLeft?: string;
  inputGroupTextRight?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  horizontal?: boolean;
  register: any;
  errors: any;
  validation?: any;
  defaultValue?: string | number | readonly string[] | undefined; // 입력 필드의 placeholder (선택 사항)
}

const TbInput: React.FC<InputProps> = ({
  label = '',
  message = '',
  name = '',
  id = '',
  type = 'text',
  min = '',
  max = '',
  step = '',
  inputGroupTextLeft = '',
  inputGroupTextRight = '',
  placeholder = '',
  disabled = false,
  readonly = false,
  horizontal = false,
  defaultValue = undefined,
  register = undefined,
  errors = {},
  validation = undefined,
  onChange = undefined,
}) => {
  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      {label && (
        <TBLabel horizontal={horizontal.toString()}>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </TBLabel>
      )}
      <div className={'w-100'}>
        <InputGroup>
          {inputGroupTextLeft && <InputGroupText>{inputGroupTextLeft}</InputGroupText>}
          <InputStyle
            className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
            id={id}
            name={name}
            type={type}
            min={min}
            max={max}
            step={step}
            placeholder={placeholder}
            defaultValue={defaultValue || ''}
            {...register(name, {
              ...validation,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                if (onChange) onChange(e);
              },
            })}
            autoComplete="true"
            disabled={disabled}
            readOnly={readonly}
          />
          {inputGroupTextRight && <InputGroupText>{inputGroupTextRight}</InputGroupText>}
        </InputGroup>
        {errors[name] && (
          <div className="text-danger mt-1">
            {errors[name]?.message ? errors[name].message : `${label}을/를 입력하세요.`}
          </div>
        )}
        {message && <div className={'mt-1'}>{message}</div>}
      </div>
    </TBFormGroup>
  );
};
export default React.memo(TbInput);

const InputStyle = styled.input`
  &[readonly] {
    background-color: #eee;
  }
`;
