import React from 'react';

export const templateFormColumns = () => [
  {
    name: 'id',
    selector: (row: any) => row.idTemplateForm,
    center: true,
    width: '60px',
  },
  {
    name: '이름',
    selector: (row: any) => row.name,
    center: true,
  },
  {
    name: '가로 크기',
    cell: (row: any) => (
      <span>
        <b>{row.unitWidth}</b> {row.sizeUnit.toLowerCase()}
      </span>
    ),
    center: true,
    width: '110px',
  },
  {
    name: '세로 크기',
    cell: (row: any) => (
      <span>
        <b>{row.unitHeight}</b> {row.sizeUnit.toLowerCase()}
      </span>
    ),
    center: true,
    width: '110px',
  },
  {
    name: '활성 여부',
    // selector: (row: any) => row.active,
    cell: (row: any) => {
      return row.active ? (
        <span className="badge badge-success">활성</span>
      ) : (
        <span className="badge badge-danger">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
];
