import React, { Fragment } from 'react';

const ComingSoon = () => {
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid p-0 m-0">
          <div className="comingsoon comingsoon-bgimg">
            <div className="comingsoon-inner text-center">
              <h5>COMING SOON</h5>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default React.memo(ComingSoon);
