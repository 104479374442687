import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ResFontFace } from '../../../api/fontFaceAPI';

interface DefaultProps {
  fontFamily: any;
  fontFace: ResFontFace;
}
const FontFaceItem = ({ fontFamily, fontFace }: DefaultProps) => {
  return (
    <Container>
      <div className={'d-flex align-items-center justify-content-between mb-2'}>
        <Label>
          {fontFace.weightValue} - {fontFace.weight}&nbsp;&nbsp;
          <span className={`badge ${fontFace.active ? 'badge-success' : 'badge-danger'}`}>
            {fontFace.active ? '활성' : '비활성'}
          </span>
        </Label>
        <Link
          className={'btn btn-outline-primary btn-sm'}
          to={`/admin/font/update/${fontFace.idFontFamily}/${fontFace.idFontFace}`}
        >
          수정
        </Link>
      </div>
      <div style={{ fontSize: 36, fontFamily: `${fontFamily?.css}`, fontWeight: fontFace.weightValue }}>
        <span>다람쥐 헌 쳇바퀴 타고파.</span>&nbsp;
        <span>The quick brown fox jumps over th lazy dog.</span>
      </div>
    </Container>
  );
};
export default React.memo(FontFaceItem);

const Container = styled.div`
  border-bottom: 1px solid #e8ebf2;
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-size: 15px;
  color: #999;
  font-weight: 700;
`;
