export function pxToMm(px: number, dpi: number) {
  return (px * 25.4) / dpi;
}

export function pxToCm(px: number, dpi: number) {
  return (px * 2.54) / dpi;
}

export function mmToPx(mm: number, dpi: number) {
  return (mm * dpi) / 25.4;
}

export function cmToPx(cm: number, dpi: number) {
  return (cm * dpi) / 2.54;
}
