import React, { Fragment, useMemo, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import { createProduct, deleteProduct, getProduct, ReqProduct, updateProduct } from '../../api/productAPI';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TbModal from '../../components/common/ui/TbModal';
import TbLoading from '../../components/common/ui/TbLoading';
import TbInput from '../../components/common/ui/form/TbInput';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbDateText from '../../components/common/ui/TbDateText';
import { getSupportLocale } from '../../api/settingAPI';
import TbSelect from '../../components/common/ui/form/TbSelect';
import { SelectItem } from '../../utils/enumUtils';

const ProductUpdate = () => {
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idProduct } = useParams<{ idProduct: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteMutate = useMutation(deleteProduct);
  const createMutate = useMutation(createProduct);
  const updateMutate = useMutation(updateProduct);
  const currencyOption = [
    { label: '한국원화(KRW)', value: 'KRW' },
    { label: '미국달러(USD)', value: 'USD' },
  ];

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqProduct>({
    defaultValues: {
      ...(!idProduct
        ? // 생성일 경우 초기값.
          {
            active: false,
          }
        : {}),
    },
  });

  const watchCurrency = watch('currency');
  const watchCost = watch('cost');
  const watchDiscountRate = watch('discountRate');

  /**
   * 지원 언어 목록 조회
   * */
  const { data: supportLocale } = useQuery(['getSupportLocale'], () => getSupportLocale(), {
    select: (data) => {
      return Object.entries(data).map(
        ([, value]): SelectItem => ({
          value: value,
          label: value as string,
        }),
      );
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData } = useQuery(['getProduct', idProduct], () => getProduct(Number(idProduct)), {
    enabled: !!idProduct && !!supportLocale,
    select: (data) => {
      return {
        ...data,
      };
    },
    onSuccess: (data) => {
      setValue('name', data.name);
      setValue('locale', data.locale);
      setValue('currency', data.currency);
      setValue('cost', data.cost);
      setValue('discountRate', data.discountRate);
      setValue('orderAscending', data.orderAscending);
      setValue('active', data.active);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    if (!isAdmin) {
      toast.warning('수정 권한이 없습니다.');
      return;
    }

    setIsLoading(false);
    if (idProduct && Number(idProduct) > 0) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idProduct: Number(idProduct),
          body: { ...data } as ReqProduct,
        });
        toast.success('상품 수정되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        await createMutate.mutateAsync({
          ...data,
        });
        toast.success('상품이 생성되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idProduct) {
      try {
        await deleteMutate.mutateAsync(Number(idProduct));
        navigate(-1);
        toggleDeleteModal();
        toast.success('상품이 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  /**
   * 어드민 권한
   * */
  const isAdmin = useMemo(() => {
    return userRole === 'ROLE_SUPER' || userRole === 'ROLE_ADMIN';
  }, [userRole]);

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <Breadcrumb parent="상품" title={`상품 ${idProduct ? '수정' : '생성'}`}>
            <div className="mb-0">
              {idProduct && isAdmin ? (
                <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                  삭제
                </Button>
              ) : (
                ''
              )}

              <Button color="secondary" className="me-3" type="submit">
                {isLoading ? <TbLoading /> : idProduct ? '수정' : '생성'}
              </Button>
              <Button onClick={() => navigate(-1)} color="primary">
                목록
              </Button>
            </div>
          </Breadcrumb>

          <Row>
            <Col sm={7}>
              <Card>
                <CardHeader>
                  <h5>요금제 정보</h5>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="이름"
                    name={'name'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbSelect
                    label="툥화"
                    name="currency"
                    options={currencyOption}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="판매 원가"
                    message={
                      <div>
                        * 정상가 :{' '}
                        <s>
                          {watchCurrency} {Number(watchCost).toLocaleString()}원
                        </s>{' '}
                        <br />* 판매가 : {watchCurrency}{' '}
                        {(Number(watchCost) * (1 - Number(watchDiscountRate))).toLocaleString()}원(
                        {Number(watchDiscountRate) * 100}% 할인)
                        <br />
                      </div>
                    }
                    name={'cost'}
                    type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="연간 할인율"
                    name={'discountRate'}
                    min={0}
                    max={1}
                    step={0.01}
                    type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm={5}>
              <Card>
                <CardHeader>
                  <h5>설정</h5>
                </CardHeader>
                <CardBody>
                  <TbSelect
                    label="지원 국가"
                    name="locale"
                    options={supportLocale || []}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="순서(오름차순)"
                    name={'orderAscending'}
                    type="number"
                    register={register}
                    errors={errors}
                  />
                  <hr className={'my-4'} />
                  <TbRadioBoolean
                    control={control}
                    message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                    label={'게시 상태'}
                    name="active"
                    register={register}
                  />
                  {idProduct && (
                    <>
                      <TbDateText label={'생성일'} date={defaultData?.createdDate} />
                      <TbDateText label={'수정일'} date={defaultData?.updatedDate} />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};
export default React.memo(ProductUpdate);
