import APIClient from './APIClient';

export type SupportLocale = 'en_US' | 'ko_KR' | 'ja_JP';

export const getSetting = async () => {
  const response = await APIClient.get(`/v2/api/setting`);
  return response.data;
};
// Patch
export const patchSetting = async (data: any) => {
  const response = await APIClient.patch(`/v2/api/setting`, data);
  return response.data;
};

export const getSupportLocale = async () => {
  const response = await APIClient.get(`/v2/api/setting/support-language`);
  return response.data;
};
