import React from 'react';
import moment from 'moment';

export const creditColumns = [
  {
    name: 'ID',
    selector: (row: any) => row.id,
    center: true,
    minWidth: '100px',
    cell: (row: any) => <div className={'text-left'}>ID.{row.id}</div>,
  },
  {
    name: '상태',
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '80px',
    cell: (row: any) => (
      <div>
        {row.status === 'ACCUMULATE' && <span className={'badge badge-info'}>적립</span>}
        {row.status === 'USE' && <span className={'badge badge-success'}>사용</span>}
        {row.status === 'EXPIRE' && <span className={'badge badge-danger'}>만료</span>}
        {row.status === 'REFUND' && <span className={'badge badge-warning'}>환불</span>}
        {row.status === 'CANCEL' && <span className={'badge badge-warning'}>취소</span>}
      </div>
    ),
  },
  {
    name: '상세',
    selector: (row: any) => row.description,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '250px',
  },
  {
    name: '거래 금액',
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
    cell: (row: any) => <div>{row.status === 'ACCUMULATE' ? `${row.leftAmount}/${row.amount}` : row.amount}</div>,
  },
  {
    name: '거래일',
    selector: (row: any) => moment(row.createdDate).format('YYYY/MM/DD HH:mm:ss'),
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '160px',
  },
  {
    name: '만료일',
    selector: (row: any) => (row.expiredDate ? moment(row.expiredDate).format('YYYY/MM/DD HH:mm:ss') : '-'),
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '160px',
  },
];
