import APIClient from './APIClient';
import { PageInfo } from './types';
import { SupportLocale } from './settingAPI';

export interface ResFontFamilyI18n {
  id?: number;
  locale?: SupportLocale;
  displayName?: string;
  image?: string;
  createdDate: string;
  updatedDate: string;
}

export interface ReqFontFamilyI18n {
  idFontFamily?: number;
  locale?: SupportLocale;
  displayName?: string;
  image?: string;
}

export interface ReqFontFamilyPage extends PageInfo {
  locale?: SupportLocale | '';
  recommend?: boolean | null;
  handwriting?: boolean | null;
  cursive?: boolean | null;
  serif?: boolean | null;
  gothic?: boolean | null;
  round?: boolean | null;
  design?: boolean | null;
  headerFont?: boolean | null;
  bodyFont?: boolean | null;
  active?: boolean | null;
}

export interface ResFontFamilyList {
  idFontFamily?: number;
  i18ns?: ResFontFamilyI18n[];
  localeList?: SupportLocale[];
  name?: string;
  // options
  recommend?: boolean | null;
  handwriting?: boolean | null;
  cursive?: boolean | null;
  serif?: boolean | null;
  gothic?: boolean | null;
  round?: boolean | null;
  design?: boolean | null;
  headerFont?: boolean | null;
  bodyFont?: boolean | null;
  active?: boolean | null;
}

export interface ReqFontFamily {
  name?: string;
  displayName?: string;
  fileName?: string;
  filePath?: string;
  image?: string;
  // css file
  css?: string;
  cssPath?: string;
  // options
  localeList?: SupportLocale[];
  recommend?: boolean | null;
  handwriting?: boolean | null;
  cursive?: boolean | null;
  serif?: boolean | null;
  gothic?: boolean | null;
  round?: boolean | null;
  design?: boolean | null;
  headerFont?: boolean | null;
  bodyFont?: boolean | null;
  active?: boolean | null;
  top?: boolean | null;
  tagList?: string[];
}

/**
 * [FontFamily] 페이징
 * */
export const getFontFamilyPage = async (params: ReqFontFamilyPage) => {
  const response = await APIClient.get('/v2/api/font-family/page', {
    params,
  });
  return response.data;
};

/**
 * [FontFamily] 단일 조회
 * */
export const getFontFamily = async (idFontFamily: number) => {
  const response = await APIClient.get(`/v2/api/font-family/${idFontFamily}`);
  return response.data;
};

/**
 * [FontFamily] 생성
 * */
export const createFontFamily = async (fontFamily: ReqFontFamily) => {
  const response = await APIClient.post('/v2/api/font-family', fontFamily);
  return response.data;
};

/**
 * [FontFamily] 수정
 * */
export const updateFontFamily = async ({ idFontFamily, body }: { idFontFamily: number; body: ReqFontFamily }) => {
  const response = await APIClient.patch(`/v2/api/font-family/${idFontFamily}`, body);
  return response.data;
};

/**
 * [FontFamily] 삭제
 * */
export const deleteFontFamily = async (idFontFamily: number) => {
  const response = await APIClient.delete(`/v2/api/font-family/${idFontFamily}`);
  return response.data;
};

/**
 * [FontFamily] 폰트 패밀리 Enum 목록
 * */
export const getFontFamilyEnum = async () => {
  const response = await APIClient.get('/v2/api/font-family/enum');
  return response.data;
};

// ===== i18n =====

/**
 * [FontFamilyI18n] 목록
 * */
export const getFontFamilyI18nList = async (idFontFamily: number) => {
  const response = await APIClient.get('/v2/api/font-family/i18n/list', {
    params: {
      idFontFamily,
    },
  });
  return response.data;
};

/**
 * [FontFamilyI18n] 단일 조회
 * */
export const getFontFamilyI18n = async (idFontFamilyI18n: number) => {
  const response = await APIClient.get(`/v2/api/font-family/i18n/${idFontFamilyI18n}`);
  return response.data;
};

/**
 * [FontFamilyI18n] 생성
 * */
export const createFontFamilyI18n = async (fontFamilyI18n: ReqFontFamilyI18n) => {
  const response = await APIClient.post('/v2/api/font-family/i18n', fontFamilyI18n);
  return response.data;
};

/**
 * [FontFamilyI18n] 수정
 * */
export const updateFontFamilyI18n = async ({
  idFontFamilyI18n,
  body,
}: {
  idFontFamilyI18n: number;
  body: ReqFontFamilyI18n;
}) => {
  const response = await APIClient.patch(`/v2/api/font-family/i18n/${idFontFamilyI18n}`, body);
  return response.data;
};

/**
 * [FontFamilyI18n] 삭제
 * */
export const deleteFontFamilyI18n = async (idFontFamilyI18n: number) => {
  const response = await APIClient.delete(`/v2/api/font-family/i18n/${idFontFamilyI18n}`);
  return response.data;
};
