import TBFlag from '../../../components/common/ui/TBFlag';
import React from 'react';

export const FontFamilyI18nColumns = (uploadFileUrl: string) => [
  {
    name: 'id',
    selector: (row: any) => row.id,
    center: true,
    width: '60px',
  },
  {
    name: '언어',
    cell: (row: any) => {
      return <TBFlag locale={row.locale} />;
    },
    sortable: true,
    center: true,
    width: '60px',
  },
  {
    name: '표기명',
    selector: (row: any) => row.displayName,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '대표 썸네일',
    cell: (row: any) => {
      return row.image ? <img height={30} src={`${uploadFileUrl}/${row.image}`} alt={row.displayName} /> : '-';
    },
    sortable: true,
    center: true,
    wrap: true,
  },
];
