export const Online = 'Online';
export const Dashboard = 'Dashboard';
export const Month = 'Month';
export const All = 'All';
export const Search = 'Search';
export const Bitcoin = 'Bitcoin';
export const Chat = 'Chat';
export const Email = 'Email';
export const Blog = 'Blog';
export const Ecommerce = 'Ecommerce';
export const Project = 'Project';
export const March = 'March';
export const Cancel = 'Cancel';
export const Settings = 'Settings';
export const Design = 'Design';
export const Color = 'Color';
export const Alert = 'Alert';
export const ModalTitle = 'Modal title';
export const Dismiss = 'dismiss';
export const Default = 'Default';
export const DropdownHeader = 'Dropdown Header';
export const Base = 'Base';
export const Forms = 'Forms';
export const Tables = 'Tables';
export const Charts = 'Charts';
export const Rating = 'Rating';
export const Scrollable = 'Scrollable';
export const Advance = 'Advance';
export const Timeline = 'Timeline';
export const Cards = 'Cards';
export const LogOut = 'Log Out';
export const General = 'General';
export const Widgets = 'Widgets';
export const Carousel = 'Carousel';
export const Icons = 'Icons';
export const Spinner = 'Spinner';
export const Right = 'Right';
export const Login = 'Login';
export const LOGIN = 'LOGIN';
export const DD = 'DD';
export const MM = 'MM';
export const YY = 'YY';
export const Date = 'Date';
export const Time = 'Time';
export const Image = 'Image';
export const CreditCard = 'Credit card';
export const Product = 'Product';
export const EricaHughes = 'Erica Hughes';
export const VincentPorter = 'Vincent Porter';
export const KoriThomas = 'Kori Thomas';
export const AinChavez = 'Ain Chavez';
export const GingerJohnston = 'Ginger Johnston';
export const PrasanthAnand = 'Prasanth Anand';
export const HileriJecno = 'Hileri Jecno';

// File-manager
export const FREE = 'FREE';
export const Upload = 'Upload';

// Task
export const Notification = 'notification';
export const GeneralManager = 'general manager';
export const ELANA = 'ELANA';
export const Delete = 'Delete';
export const Close = 'Close';
export const Knowledgebase = 'Knowledgebase';
export const BACK_TO_HOME_PAGE = 'BACK TO HOME PAGE';
export const English = 'English';
export const Spanish = 'Spanish';
export const Portuguese = 'Portuguese';
export const French = 'French';
export const FRIENDLIST = 'FRIEND LIST';
export const Customizer = 'Customizer';
export const Customize = 'Customize';
export const PreviewRealTime = 'Preview Real Time';
export const Configuration = 'Configuration';
export const CopyText = 'Copy text';
export const LayoutType = 'Layout Type';
export const LTRLayout = 'LTR Layout';
export const RTLLayout = 'RTL Layout';
export const SidebarType = 'Sidebar Type';
export const SidebarSettings = 'Sidebar settings';
export const IconColor = 'icon Color';
export const LightLayout = 'Light layout';
export const DarkLayout = 'Dark Layout';
export const MixLayout = 'Mix Layout';
export const SidebarBackgroundSetting = 'Sidebar background setting';
export const Pattern = 'Pattern';
export const Border = 'Border';
