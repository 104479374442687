import { SupportLocale } from '../../../api/settingAPI';
import React from 'react';

interface DefaultProps {
  locale?: SupportLocale;
}
const TBFlag = ({ locale }: DefaultProps) => {
  return (
    <span style={{ border: '1px solid #ccc' }}>
      {locale === 'en_US' && <i className="flag-icon flag-icon-us"></i>}
      {locale === 'ko_KR' && <i className="flag-icon flag-icon-kr"></i>}
      {locale === 'ja_JP' && <i className="flag-icon flag-icon-jp"></i>}
    </span>
  );
};
export default React.memo(TBFlag);
