import { cellsStyle, headCellsStyle, rowsStyle } from '../../../data/customizer/react-data-table-config';
import DataTable from 'react-data-table-component';
import React from 'react';
import {
  PaginationChangePage,
  PaginationChangeRowsPerPage,
  TableColumn,
  // eslint-disable-next-line import/no-unresolved
} from 'react-data-table-component/dist/DataTable/types';

type DefaultProps<T> = {
  data: any[];
  columns: TableColumn<T>[];
  pagination?: boolean;
  progressPending?: boolean;
  paginationDefaultPage?: number;
  paginationTotalRows?: number;
  paginationPerPage?: number;
  paginationRowsPerPageOptions?: number[];
  onChangeRowsPerPage?: PaginationChangeRowsPerPage;
  onChangePage?: PaginationChangePage;
  onRowClicked?: (row: T, e: React.MouseEvent) => void;
};

const TbDataTable = ({
  data,
  progressPending,
  columns,
  pagination = true,
  paginationDefaultPage,
  paginationTotalRows,
  paginationPerPage,
  paginationRowsPerPageOptions = [5, 10, 30, 50, 100],
  onChangeRowsPerPage,
  onChangePage,
  onRowClicked,
}: DefaultProps<any>) => {
  return (
    <DataTable
      noHeader
      columns={columns}
      data={data}
      progressPending={progressPending}
      highlightOnHover
      pointerOnHover
      pagination={pagination}
      paginationServer
      paginationDefaultPage={paginationDefaultPage}
      paginationTotalRows={paginationTotalRows}
      paginationPerPage={paginationPerPage}
      paginationRowsPerPageOptions={paginationRowsPerPageOptions}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onRowClicked={onRowClicked}
      dense={true}
      customStyles={{
        rows: rowsStyle,
        headCells: headCellsStyle,
        cells: cellsStyle,
      }}
    />
  );
};

export default React.memo(TbDataTable);
