import React from 'react';
import moment from 'moment/moment';

export const templateColumns = () => [
  {
    name: '미리보기',
    cell: (row: any) => {
      return (
        <div>
          {row.thumbnail ? (
            <img
              src={row.thumbnail}
              alt={'템플릿 썸네일'}
              style={{
                width: '100%',
                height: '100%',
                maxWidth: '100px',
                maxHeight: '70px',
              }}
            />
          ) : (
            '미리보기 없음'
          )}
        </div>
      );
    },
    center: true,
    width: '100px',
  },
  {
    name: '이름',
    selector: (row: any) => row.name,
    center: true,
  },
  {
    name: '브랜드명',
    selector: (row: any) => row.brandName,
    center: true,
  },
  {
    name: '활성 여부',
    // selector: (row: any) => row.active,
    cell: (row: any) => {
      return row.active ? (
        <span className="badge badge-success">활성</span>
      ) : (
        <span className="badge badge-danger">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
  {
    name: '최근 업데이트',
    sortable: true,
    center: true,
    wrap: true,
    maxWidth: '140px',
    cell: (row: any) => (row.updatedDate ? moment(row.updatedDate).format('YYYY/MM/DD HH:mm:ss') : '-'),
  },
];
