import React, { useEffect } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import FontFaceItem from './FontFaceItem';
import { useNavigate } from 'react-router-dom';
import { RefreshCcw } from 'react-feather';
import { ResFontFace } from '../../../api/fontFaceAPI';

interface DefaultProps {
  fontFamily: any;
}

const FontFaceList = ({ fontFamily }: DefaultProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (fontFamily?.cssPath) {
      loadCss(fontFamily?.cssPath);
    }
  }, [fontFamily]);

  const loadCss = (href: string) => {
    try {
      return new Promise<void>((resolve) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;

        link.onload = () => resolve();
        // link.onerror = () => reject(new Error(`Failed to load CSS: ${href}`));

        document.head.appendChild(link);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const unloadCss = (href: string) => {
    const links = document.querySelectorAll(`link[href="${href}"]`);
    links.forEach((link) => link.parentNode?.removeChild(link));
  };

  const reLoadCss = () => {
    unloadCss(fontFamily?.cssPath);
    loadCss(fontFamily?.cssPath);
  };

  return (
    <Card>
      <CardHeader>
        <h5>폰트 페이스</h5>
        <div>
          <Button onClick={() => reLoadCss()} color={'primary'} outline={true} className={'m-r-10'}>
            <RefreshCcw size={15} />
          </Button>
          <Button onClick={() => navigate(`/admin/font/create/${fontFamily?.idFontFamily}`)} color={'primary'}>
            새로 등록
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        {fontFamily?.fontFaces?.map((fontFace: ResFontFace) => (
          <FontFaceItem key={fontFace.idFontFace} fontFamily={fontFamily} fontFace={fontFace} />
        ))}
        {(!fontFamily?.fontFaces || fontFamily?.fontFaces.length === 0) && (
          <div className={'text-danger text-center'}>폰트 페이스를 등록하세요.</div>
        )}
      </CardBody>
    </Card>
  );
};
export default React.memo(FontFaceList);
