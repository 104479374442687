import APIClient from './APIClient';
import { PageInfo } from './types';
import { ResTemplateForm } from './templateFormAPI';

export type TemplateCreatedType = 'USER' | 'BRAND' | 'MARKET';
export type TemplateDeletedType = 'USED' | 'TRASH_DIRECT' | 'TRASH_PARENT';
export type TemplateShareType = 'PUBLIC' | 'PRIVATE';
export interface ReqTemplate {
  name: string;
  createdType: TemplateCreatedType;
  deletedType: TemplateDeletedType;
  shareType: TemplateShareType;
  thumbnail: string;
  idForm: number;
  idCategories: string[];
  keywordList: string[];
  color: string;
  idBrand: number;
  idBundle: number;
  idDirectory: number;
  top: boolean;
  active: boolean;
  body: string;
}

export interface ReqTemplatePage extends PageInfo {
  hexColor?: string;
  type?: 'DESIGN' | 'GOOGLE_FILE';
  createdType: TemplateCreatedType | '';
  idCategory?: string;
  idForm?: string;
  active?: boolean | null;
}

export interface ResTemplate {
  idTemplate: number;
  type: string;
  name: string;
  createdType: TemplateCreatedType;
  deletedType: TemplateDeletedType;
  shareType: TemplateShareType;
  thumbnail: string;
  designUpdatedDate: string;
  form: ResTemplateForm;
  design: any;
  categories: any[];
  tags: any[];
  colorGroups: any[];
  keywordList: string[];
  idBrand: number;
  idBundle: number;
  idDirectory: number;
  idUser: number;
  top: boolean;
  active: boolean;
  createdDate: string;
  updateDate: string;
}

/**
 * 템플릿 페이징
 * */
export const getTemplatePage = async (params: ReqTemplatePage) => {
  const response = await APIClient.get('/v2/api/template/page', {
    params,
  });
  return response.data;
};

/**
 * 템플릿 단일 조회
 * */
export const getTemplate = async (id: number) => {
  const response = await APIClient.get(`/v2/api/template/${id}`);
  return response.data;
};

/**
 * 템플릿 생성
 * */
export const createTemplate = async (template: ReqTemplate) => {
  const response = await APIClient.post('/v2/api/template', template);
  return response.data;
};

/**
 * 템플릿 수정
 * */
export const patchTemplate = async (id: string, template: ReqTemplate) => {
  const response = await APIClient.patch(`/v2/api/template/${id}`, template);
  return response.data;
};

/**
 * 템플릿 삭제
 * */
export const deleteTemplate = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/template/${id}`);
  return response.data;
};
