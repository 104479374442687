import APIClient from './APIClient';

export interface ReqFontFace {
  weight?: string;
  weightName?: string;
  image?: string;
  otf?: string;
  ttf?: string;
  woff?: string;
  woff2?: string;
  active?: boolean | null;
  idFontFamily?: number;
}

export interface ResFontFace {
  idFontFace?: number;
  idFontFamily?: number;
  weight?: string;
  weightValue?: string;
  weightName?: string;
  image?: string;
  otf?: string;
  ttf?: string;
  woff?: string;
  woff2?: string;
  fontFamily?: string;
  active?: boolean;
  createdDate?: string;
  updatedDate?: string;
}

/**
 * [FontFace] 리스트
 * */
export const getFontFaceList = async (params: { idFontFamily: number }) => {
  const response = await APIClient.get('/v2/api/font-face/list', {
    params,
  });
  return response.data;
};

/**
 * [FontFace] 단일 조회
 * */
export const getFontFace = async (idFontFace: number) => {
  const response = await APIClient.get(`/v2/api/font-face/${idFontFace}`);
  return response.data;
};

/**
 * [FontFace] 생성
 * */
export const createFontFace = async (fontFace: ReqFontFace) => {
  const response = await APIClient.post('/v2/api/font-face', fontFace);
  return response.data;
};

/**
 * [FontFace] 수정
 * */
export const updateFontFace = async ({ idFontFace, body }: { idFontFace: number; body: ReqFontFace }) => {
  const response = await APIClient.patch(`/v2/api/font-face/${idFontFace}`, body);
  return response.data;
};

/**
 * [FontFace] 삭제
 * */
export const deleteFontFace = async (idFontFace: number) => {
  const response = await APIClient.delete(`/v2/api/font-face/${idFontFace}`);
  return response.data;
};
